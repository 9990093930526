/* -------------------- 메인 컨텐츠 -------------------- */
.main-content {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0;
    padding-bottom: 30px;
    position: relative;
    margin-top: 55px;
}

/* -------------------- 헤더 영역 (탭 + 스케줄 생성 버튼) -------------------- */
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;  /* 두 요소 사이 여백, 원하는 값으로 조정 */
    width: 100%;
    margin-bottom: 10px;
  }

.tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;
    gap: 4px;
    padding: 6px 12px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 8px;
}
.tab-container::-webkit-scrollbar {
    display: none;
}
.tab-item {
    font-size: 1rem;
    color: #444;
    cursor: pointer;
    padding: 6px 12px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    transition: color 0.3s ease, background-color 0.3s ease;
    text-align: center;
}
.tab-item:hover {
    color: #333;
}
.tab-item:not(.active) {
    box-shadow: none;
}
.tab-item.active {
    font-weight: bold;
    color: #1a73e8;
    background-color: transparent;
}

/* 스케줄 생성 버튼 - 강사 전용 */
.schedule-create-button {
    position: absolute;
    right: 130px;  /* 웹에서 오른쪽 끝에서 50px 띄움 */
    padding: 4px 8px;
    font-size: 8px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.schedule-create-button:hover {
    background-color: #0056b3;
}


/* 버튼 컨테이너: 버튼을 오른쪽 정렬 */
.instructor-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

/* -------------------- 월 변경 영역 -------------------- */
.month-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-10px);
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin: 0;
}
.month-selector button {
    background: none;
    border: none;
    font-size: 1.4rem;
    color: #000;
    cursor: pointer;
    padding: 8px;
    margin: 0 10px;
}
.month-selector span {
    font-size: 1.3rem;
    font-weight: bold;
    color: #000;
}

/* -------------------- 달력 컨테이너 (요일 라벨+날짜 그리드) -------------------- */
.calendar-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 0;
}

.calendar-week-labels {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: bold;
    padding: 0 5px;
    transform: translateY(-10px);
}
.calendar-week-day:nth-child(1) {
    color: #e60000;
}
.calendar-week-day:nth-child(7) {
    color: #0066cc;
}
.calendar-week-day {
    font-size: 1rem;
    color: #333;
}

/* 달력 그리드 */
.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    padding: 5px;
    transform: translateY(-10px);
}

/* 날짜 셀 */
.calendar-day {
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}
.calendar-day:nth-child(7n+1) {
    color: #e60000;
}
.calendar-day:nth-child(7n) {
    color: #0066cc;
}
.calendar-day:hover {
    background: none;
}
.calendar-day:hover::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    background: #f2f2f2;
    border-radius: 50%;
    z-index: -1;
}
.calendar-day.today {
    outline: 2px solid #ffa726;
    outline-offset: -2px;
    font-weight: bold;
}
.calendar-day.selected {
    background: none;
    color: #fff;
    font-weight: bold;
    position: relative;
}
.calendar-day.selected::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    background: #1a73e8;
    border-radius: 50%;
    z-index: -1;
}
.calendar-day .schedule-dots {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    gap: 2px;
    z-index: 9999;
}
.calendar-day .schedule-dots span {
    width: 4px;
    height: 4px;
    background: #1a73e8;
    border-radius: 50%;
}

/* 카드와 카드 사이 버튼 바 */
/* 버튼 바 */
.button-bar {
    width: 85%;
    margin: 0 auto; /* 좌우 중앙 정렬 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;        /* 버튼 사이 간격 */
    padding: 8px 0;
    /* border-bottom: 1px solid #ddd; // 필요 시 사용 */
  }
  
  /* 버튼 스타일 */
  .detail-btn, .pay-btn, .cancel-btn {
    flex: 1;
    padding: 10px 0;
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    border: none;
}
  
  .detail-btn {
    background-color: #ffffff;
    color: #6252f5;
    border: 1px solid #6252f5;
  }
  
  .pay-btn {
    background-color: #6252f5;
    color: #ffffff;
  }

  .cancel-btn {
      background-color: #f74444;
      color: #ffffff;
  }
  
  .detail-btn:hover {
    background-color: #eee;
  }
  
  .pay-btn:hover  .cancel-btn:hover {
    opacity: 0.8;
  }

/* -------------------- 반응형 (모바일 최적화) -------------------- */
@media (max-width: 500px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 10px;
        position: static;
    }
    .top-bar.instructor-mode {
        position: relative;
        min-height: 40px;
    }
    .tab-container {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        width: 100%;
        padding: 8px 5px;
        margin: 0 auto;
    }
    .tab-item {
        flex: 0 0 calc(25% - 4px);
        font-size: 0.9rem;
        padding: 6px;
        text-align: center;
        box-sizing: border-box;
    }
    .schedule-create-button {
        position: absolute;
        right: 30px;
        top: 0;
        margin-top: 0;
    }
    .month-selector {
        margin-top: 10px;
        margin-bottom: 8px;
        font-size: 1.1rem;
    }
    .month-selector button {
        font-size: 1.2rem;
        margin: 0 6px;
    }
    .month-selector span {
        font-size: 1.2rem;
    }
    .calendar-container {
        max-width: 95%;
        padding: 0 5px;
    }
    .calendar-week-labels {
        font-size: 0.8rem;
        padding: 0 5px;
    }
    .calendar {
        gap: 3px;
        padding: 5px 3px;
    }
    .calendar-day {
        font-size: 0.8rem;
    }
    .calendar-day.selected::before {
        width: 80%;
        height: 80%;
    }
    .calendar-day:hover::after {
        width: 80%;
        height: 80%;
    }
    .calendar-day.selected:hover::after {
        display: none;
    }
    .calendar-day .schedule-dots {
        top: 80%;
    }
}
