/* 네비게이션 바 스타일 */
.navigation {
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  /* position: relative; */
  top: 0;
  left: 0;
  z-index: 1000;
}

.nav-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 12px;
  height: 100%;
  display: flex;
  justify-content: space-between;  /* 요소들을 균등하게 배치 */
  align-items: center;
}

.nav-logo img {
  width: 80px;
  height: auto;
}

.nav-link-item {
  text-decoration: none;
  color: #6C38FF;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color 0.3s ease;
}

.nav-link-item span {
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
}

.nav-link-item i {
  margin-bottom: 0.3rem; /* 아이콘과 텍스트 사이 여백 */
  font-size: 1.5rem;
  color: inherit; /* 부모 요소의 색상 상속 */
}

.nav-link-item:hover {
  color: #5829E3;
}

.nav-auth {
  display: flex;
  align-items: center;
}

.nav-user {
  display: flex;
  flex-direction: column; /* 수직 정렬 */
  align-items: center; /* 중앙 정렬 */
  color: #555555; /* 사용자 아이콘 및 텍스트 기본 색상 변경 */
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
  position: relative;
}

.user-icon-level {
  display: flex;
  align-items: center;
}

.user-icon-level i {
  font-size: 1.4rem;
  color: #007bff; /* 사용자 아이콘 색상 유지 */
  margin-right: 0.5rem; /* 아이콘과 레벨 사이 간격 */
}

.user-level {
  font-size: 1rem;
  color: #eb3d2a;
}

.user-name {
  margin-top: 0.3rem; /* 아이디와 레벨 사이 간격 */
  color: #555555;
}

.nav-user:hover {
  color: #0056b3;
}

.nav-login {
  /* Primary 버튼 스타일 적용 */
  background-color: #6C38FF;
  color: #ffffff;
  border: none;
  padding: 8px 24px;
  border-radius: 24px;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  height: 36px;
}

.nav-login:hover {
  background-color: #5829E3;
  transform: none;
}

.nav-login:active {
  background-color: #6C38FF;
}

.nav-login:disabled {
  background-color: #D9D9D9;
  color: #ffffff;
  cursor: not-allowed;
}

/* 기존 아이콘 관련 스타일 제거 */
.nav-login i {
  display: none;
}

/* 추가 스타일 - 최신 트렌드 반영 */
:root {
  --primary-color: #6C38FF;
  --secondary-color: #555555;
  --hover-color: #5829E3;
  --level-color: #eb3d2a; /* 레벨 색상 변수 변경 */
}

.nav-link-item:hover,
.nav-user:hover {
  color: var(--primary-color);
}

.nav-user {
  color: var(--secondary-color);
}

.user-level {
  color: var(--level-color);
}

.nav-login:hover {
  background-color: var(--hover-color);
}

.nav-icon {
  width: 26px;
  height: 26px;
  object-fit: contain;
}
