.App {
    width: 100%;
}

.App-header {
    width: 100%;
}

.App-body {
    width: 100%;
    padding-bottom: 2rem;
}