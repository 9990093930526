.detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.detail-image {
    width: 100%;
    max-width: 800px;
    border-radius: 20px;
    margin-bottom: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.detail-content {
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.detail-content h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 10px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
}

.detail-subcontent {
    font-size: 1.2rem; /* 글자 크기 증가 */
    font-weight: bold; /* 글자 굵게 */
    color: #4a5568;
    margin-bottom: 10px;
}

.additional-info {
    background-color: #e9f5ff;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    overflow-x: hidden;
}

.additional-info p {
    font-size: 1.1rem; /* 글자 크기 증가 */
    color: #2c3e50;
    margin: 0;
    line-height: 1.6; /* 줄간격 넓게 */
}

.additional-info img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px 0;
}

.free-practice-checkbox {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.free-practice-checkbox:hover {
  background-color: #e9ecef;
}

.free-practice-checkbox .form-check-input {
  margin: 0 1rem 0 0.1rem;
}

.free-practice-checkbox .form-check-label {
  font-weight: 500;
  color: #495057;
}

.apply-button {
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  width:52%;
  border: none;
  color: white;
  padding: 6px 10px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.wait-button {
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    width:100%;
    border: none;
    color: white;
    padding: 6px 10px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
  }

.apply-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.wait-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

.apply-button:active {
  transform: translateY(1px);
}

.wait-button:active {
    transform: translateY(1px);
  }
  

.price-selection {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.price-label {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.price-select {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #333;
    appearance: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.price-select:hover, .price-select:focus {
    border-color: #3498db;
    box-shadow: 0 4px 8px rgba(52, 152, 219, 0.1);
    outline: none;
}

.price-select::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    pointer-events: none;
    color: #3498db;
}

.participant-link {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.participant-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.participant-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;
}

.participant-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.participant-info h2 {
    margin-bottom: 1rem;
    color: #333;
}

.participant-info p {
    margin-bottom: 1rem;
    font-size: 1.4rem;
    color: #555;
    display: flex;
    align-items: center;
}

.participant-info p span {
    color: #333;
    font-weight: 600;
    margin-right: 0.5rem;
    min-width: 100px;
}

.close-modal-btn {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.close-modal-btn:hover {
    background-color: #0056b3;
}

.modal-content {
    padding: 0 !important;
    border-radius: 20px;
    border: none;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
}

.modal-header {
    border-bottom: none;
    padding: 2rem 2rem 1rem;
}

.modal-schedule-title {
    font-size: 2.0rem !important;
    font-weight: 700;
    color: #333;
    width: 100%;
    text-align: center;
}

.modal-body {
    padding-left: 1rem;
}

.participant-info table {
    width: 100%;
    border-collapse: collapse;
}

.participant-info th, .participant-info td {
    padding: 0.5rem;
    font-size: 1.2rem ;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.participant-info th {
    font-weight: 600;
    color: #333;
    width: 30%;
}

.participant-info td {
    color: #555;
}

.modal-footer {
    border-top: none;
    padding: 1rem 2rem 2rem;
    justify-content: center;
}

.btn-outline-secondary {
    color: #fff;
    background-color: #6c757d;
    border: none;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    transition: all 0.3s ease;
}

.btn-outline-secondary:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(108, 117, 125, 0.3);
}

.alert-popup {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: center;
    max-width: 400px;
    width: 90%;
    margin: 0 auto;
    animation: popIn 0.3s ease-out;
}

@keyframes popIn {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.alert-popup-message {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.alert-popup-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.alert-popup-button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(52, 152, 219, 0.3);
}

.alipay-button {
  width: 44%;
  background-color: #00a1e9;
  color: white;
  border: none;
  padding: 10px 10px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alipay-button:hover {
  background-color: #0077b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 161, 233, 0.3);
}

.alipay-button i {
  margin-right: 0.5rem;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.mr-2 {
  margin-right: 0.5rem;
}

.schedule_detail_additional-participant-button {
    background-color: #3f3ce9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .additional-participant-button:hover {
    background-color: #2f2cb0;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .additional-participant-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .additional-participant-button i {
    margin-right: 8px;
  }