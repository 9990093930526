/* ✅ 기본 (공통 스타일) */
.event-section {
  margin-bottom: 20px;
  position: relative;
}

.slide {
  display: flex !important;
  margin-top: 2rem;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.slide-block {
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* ✅ 웹 전용 스타일 (1024px 이상) */
@media (min-width: 1024px) {
  .event-image {
    width: 768px;
    display: inline-block !important;
    height: 350px; /* ✅ 기존과 동일한 높이 */
    border-radius: 10px;
    cursor: pointer;
    object-fit: cover;
  }

    .sports-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr); /* ✅ 기존과 동일한 4열 구조 */
      gap: 1rem;
      padding: 1rem;
      width: 768px;
      margin: 0 auto; /* ✅ 가운데 정렬 */
    }
  
    .sport-item {
      display: flex;
      flex-direction: column; /* ✅ 아이콘 위, 글자 아래 */
      /* align-items: flex-start; */
      align-items: center; 
      text-align: center; /* ✅ 텍스트 중앙 정렬 */
      justify-content: center;
    }
  
    .sport-item img {
      width: 80px; /* ✅ 기존 크기 유지 */
      height: 80px;
      margin-bottom: -5px; /* ✅ 아이콘과 글자 사이 간격 줄이기 (기존 10px → 5px) */
    }
  
    .sport-item span {  
      font-size: 16px; /* ✅ 기존과 동일한 폰트 크기 */
      font-weight: 600;
      color: #1A1A1A;
      margin-top: 2px; /* ✅ 아이콘과 글자 사이 간격 더 줄이기 (기존 5px → 2px) */
    }
  
    .sport-item p {
      font-size: 0.8em; /* ✅ 기존과 동일한 폰트 크기 */
      color: #333;
      margin-top: 2px; /* ✅ 설명 텍스트 위치 조정 (기존 3px → 2px) */
      font-weight: 500;
    }
  }  

@media (max-width: 1023px) {
  .event-image {
    width: 90vw;
    display: inline-block !important;
    height: 200px; /* ✅ 기존과 동일한 높이 */
    border-radius: 10px;
    object-fit: cover;
    margin-top: 10px;
  }

  .sports-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* ✅ 기존과 동일한 4열 구조 */
    gap: 1rem;
    padding: 1rem;
  }

  /* ✅ 아이콘 크기 줄이기 & 위로 올리기 */
  .sport-item {
    display: flex;
    flex-direction: column;
    align-items: center; /* ✅ 아이콘 및 텍스트 중앙 정렬 */
    text-align: center; /* ✅ 텍스트 중앙 정렬 */
    margin-top: -10px; /* ✅ 전체적인 위치를 위로 이동 */
  }

  .sport-item img {
    width: 65px; /* ✅ 아이콘 크기 줄이기 (기존 70px → 60px) */
    height: 65px;
    margin-bottom: 5px; /* ✅ 아이콘과 텍스트 사이 여백 추가 */
  }

  /* ✅ 폰트 두껍게 & 중앙 정렬 */
  .sport-item span {
    font-size: 13px; /* ✅ 폰트 크기 조금 더 줄이기 (기존 14px → 13px) */
    font-weight: 600; /* ✅ 폰트 두껍게 */
    text-align: center; /* ✅ 중앙 정렬 */
    display: block;
    margin-top: 3px; /* ✅ 아이콘과 글자 사이 간격 조정 */
  }

  .sport-item p {
    font-size: 0.65em; /* ✅ 폰트 크기 줄이기 (기존 0.7em → 0.65em) */
    font-weight: 500; /* ✅ 폰트 두껍게 */
    text-align: center; /* ✅ 중앙 정렬 */
    margin-top: 2px;
  }
}

/* ✅ 더 작은 모바일 화면 (600px 이하) */
@media (max-width: 600px) {
  .sport-item img {
    width: 55px; /* ✅ 작은 화면에서 아이콘 크기 더 줄이기 */
    height: 55px;
  }

  .sport-item span {
    font-size: 12px; /* ✅ 작은 화면에서 폰트 크기 조정 */
  }

  .sport-item p {
    font-size: 0.6em; /* ✅ 작은 화면에서 폰트 크기 더 줄이기 */
  }
}
/* ✅ 모바일 & 태블릿 전용 스타일 (1023px 이하) */
@media (max-width: 1023px) {
  .slick-dots {
    position: absolute !important; /* ✅ 위치 고정 */
    bottom: -17px !important; /* ✅ 점을 더 아래로 이동 */
    left: 50%;
    transform: translateX(-50%); /* ✅ 중앙 정렬 유지 */
  }

  .slick-dots li {
    width: 6px;
    height: 6px;
    margin: 0 3px;
  }

  .slick-dots li button {
    width: 6px;
    height: 6px;
  }

  .slick-dots li button:before {
    font-size: 6px;
  }

  .slick-dots li.slick-active button:before {
    font-size: 7px;
  }
}

/* ✅ 더 작은 모바일 화면 (600px 이하) */
@media (max-width: 600px) {
  .slick-dots {
    bottom: -15px !important; /* ✅ 더 작은 화면에서 점을 덜 내리기 */
  }

  .slick-dots li {
    width: 5px;
    height: 5px;
    margin: 0 2px;
  }

  .slick-dots li button {
    width: 5px;
    height: 5px;
  }

  .slick-dots li button:before {
    font-size: 5px;
  }

  .slick-dots li.slick-active button:before {
    font-size: 6px;
  }
}
