.product-detail-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  position: relative;
  padding-bottom: 80px;
  font-family: 'Noto Sans KR', sans-serif;
  color: #333;
}

/* Product Header */
.product-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: none;
}

.back-button {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  margin-top: -30px;
}

.back-button .icon {
  font-size: 20px;
}

.share-button {
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.share-button .icon {
  font-size: 18px;
}

/* Product Images */
.product-image-slider {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.product-image-slider .image-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f8f8f8;
  margin-top: 25px;
}

.product-image-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slider-arrow.prev {
  left: 16px;
}

.slider-arrow.next {
  right: 16px;
}

.slider-arrow .icon {
  font-size: 20px;
}

.image-pagination {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.image-pagination span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.image-pagination span.active {
  background: white;
  width: 10px;
  height: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

/* Product Info */
.product-info-section {
  padding: 24px 16px;
  background: white;
  margin-bottom: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.product-header-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.product-title-section {
  flex: 1;
  margin-right: 16px;
}

.product-brand {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.product-name {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
  color: #222;
}

.product-action-buttons {
  display: flex;
  gap: 8px;
}

.favorite-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f8f9fa;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.favorite-button.active {
  color: #ff4757;
}

.favorite-button .icon {
  font-size: 20px;
  color: #ff4757;
}

.product-price {
  margin-bottom: 16px;
}

.discount-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
}

.discount-rate {
  color: #ff4757;
  font-weight: 600;
  font-size: 1.1rem;
}

.original-price {
  color: #aaa;
  text-decoration: line-through;
  font-size: 0.9rem;
}

.current-price {
  font-size: 1.6rem;
  font-weight: 700;
  color: #222;
}

/* 새로 추가된 혜택 정보 */
.benefits-info {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.benefit-item {
  display: flex;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.benefit-label {
  flex: 0 0 80px;
  color: #888;
}

.benefit-value {
  flex: 1;
  color: #333;
}

/* 상품 옵션 선택 영역 */
.product-options-section {
  padding: 16px;
  background: white;
  margin-bottom: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.option-selector {
  margin-bottom: 12px;
}

.option-header {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.option-icon {
  color: #5f3dc4;
  margin-right: 6px;
  font-size: 14px;
}

.option-title {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
}

.option-select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23333' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-color: white;
  font-size: 0.9rem;
}

.option-select:focus {
  outline: none;
  border-color: #03c75a;
}

/* 수량 선택 */
.quantity-selector {
  margin-bottom: 12px;
}

.quantity-control {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.quantity-button {
  width: 36px;
  height: 36px;
  background: #f8f9fa;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
}

.quantity-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity-input {
  flex: 1;
  height: 36px;
  border: none;
  text-align: center;
  font-size: 0.9rem;
}

.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 선택된 옵션 표시 */
.selected-options {
  margin-bottom: 16px;
  padding: 16px;
  background: #f9f7ff;
  border-radius: 8px;
  border: 1px solid #e0d8ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.selected-options-title {
  font-size: 16px;
  font-weight: 600;
  color: #5f3dc4;
  margin: 0 0 12px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0d8ff;
}

.selected-option-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.selected-option-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option-info-row {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
}

.option-info-icon {
  color: #5f3dc4;
  font-size: 14px;
  width: 20px;
  margin-right: 8px;
}

.option-info-label {
  font-weight: 500;
  color: #555;
  width: 80px;
  flex-shrink: 0;
}

.option-info-value {
  font-weight: 500;
  color: #222;
  flex: 1;
}

.selected-option-price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px dashed #e0d8ff;
}

.selected-option-price-label {
  font-weight: 600;
  font-size: 15px;
  color: #333;
}

.selected-option-price {
  font-size: 18px;
  font-weight: 700;
  color: #5f3dc4;
}

/* 총 주문 금액 */
.total-price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  border-top: 1px solid #eee;
  margin-top: 16px;
}

.total-price-label {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.total-price-value {
  font-size: 1.4rem;
  font-weight: 700;
  color: #03c75a;
}

/* Content Section */
.content-section {
  background: white;
  padding: 0;
  margin-bottom: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding-top: 30px;
}

.content-block {
  padding: 24px 16px;
  border-bottom: 8px solid #f5f5f5;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.section-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #222;
  margin: 0;
}

.write-review-btn,
.write-inquiry-btn {
  background: #f8f9fa;
  border: 1px solid #ddd;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  color: #555;
}

.write-review-btn:hover,
.write-inquiry-btn:hover {
  background: #f1f3f5;
}

/* 상세 이미지 */
.detail-images {
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: 600px;
  transition: max-height 0.5s ease;
}

.detail-images.expanded {
  max-height: 10000px;
}

.detail-image-wrapper {
  width: 100%;
  margin-bottom: 20px;
  background-color: #f8f8f8;
  overflow: hidden;
}

.detail-image-wrapper img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
  object-position: center;
}

.toggle-detail-button {
  width: 100%;
  padding: 16px;
  background: white;
  border: none;
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  color: #666;
  font-size: 0.9rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05);
}

.detail-images.expanded .toggle-detail-button {
  position: relative;
}

/* 리뷰 스타일 */
.review-item {
  margin-bottom: 0;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.review-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.review-author {
  font-size: 0.95rem;
  font-weight: 500;
}

.review-date {
  font-size: 0.85rem;
  color: #888;
}

.review-image-container {
  margin-top: 16px;
}

.view-more-button {
  width: 100%;
  padding: 12px;
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #666;
  cursor: pointer;
  margin-top: 16px;
}

.view-more-button:hover {
  background: #f1f3f5;
}

.review-rating {
  color: #ddd;
}

.star.filled {
  color: #ffc107;
}

.review-content {
  font-size: 0.95rem;
  color: #444;
  line-height: 1.6;
  margin: 0;
}

.no-reviews, 
.no-inquiries {
  color: #888;
  font-size: 0.95rem;
  text-align: center;
  padding: 32px 0;
}

/* Bottom Action Buttons */
.product-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  padding: 12px 16px;
  border-top: 1px solid #eee;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  z-index: 100;
}

.action-buttons-container {
  max-width: 768px;
  margin: 0 auto;
}

.favorite-action-button {
  height: 56px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #555;
  font-size: 0.85rem;
}

.favorite-action-button .action-icon {
  font-size: 20px;
  color: #ff4757;
  margin-bottom: 4px;
}

.checkout-button {
  width: 100%;
  height: 48px;
  background: #5f3dc4;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(95, 61, 196, 0.2);
  transition: background-color 0.2s, transform 0.1s;
}

.checkout-button:hover {
  background: #4c3199;
}

.checkout-button:active {
  transform: translateY(1px);
}

.checkout-text {
  font-size: 1rem;
  font-weight: 600;
}

/* 상품 페이지 탭 메뉴 */
.product-tabs {
  position: sticky;
  top: 45px;
  background: white;
  z-index: 99;
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.tabs-container {
  display: flex;
  justify-content: space-around;
  max-width: 768px;
  margin: 0 auto;
}

.tab-button {
  flex: 1;
  padding: 14px 0;
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 0.95rem;
  font-weight: 500;
  color: #666;
  cursor: pointer;
  transition: all 0.2s;
}

.tab-button.active {
  color: #5f3dc4;
  border-bottom: 2px solid #5f3dc4;
  font-weight: 600;
}

/* 리뷰 섹션 개선 */
.review-summary {
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.review-rating-average {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.star-icon {
  font-size: 24px;
  color: #ff9500;
  margin-right: 8px;
}

.rating-number {
  font-size: 24px;
  font-weight: 700;
  color: #222;
}

.review-images-preview {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 8px;
}

.review-image-box {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
}

.review-image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.reviews-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.review-stars {
  margin-bottom: 8px;
}

/* 각 섹션에 ID 기반 스타일 추가 */
#detail-section,
#review-section,
#qna-section,
#return-section {
  scroll-margin-top: 95px;
  padding-top: 20px;
}

/* 반응형 조정 */
@media (min-width: 768px) {
  .product-detail-page {
    max-width: 768px;
    margin: 0 auto;
  }
  
  .product-header {
    max-width: 768px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .image-container {
    padding-top: 75%;
  }
  
  .slider-arrow {
    width: 48px;
    height: 48px;
  }
  
  .product-name {
    font-size: 1.6rem;
  }
  
  .product-info-section,
  .content-block {
    padding: 32px 24px;
  }
  
  .product-actions {
    max-width: 768px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .tab-button {
    font-size: 1rem;
    padding: 16px 0;
  }
  
  .product-tabs {
    max-width: 768px;
    left: 50%;
    transform: translateX(-50%);
    top: 45px;
  }
  
  .back-button {
    left: calc(50% - 368px); /* (768px / 2) - 16px */
  }
}

/* 중복되는 스타일 정리 */
.toggle-detail-button .icon {
  font-size: 14px;
  margin-left: 4px;
}

.loading-dates {
  padding: 10px;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 4px;
  color: #6c757d;
  font-size: 14px;
  margin-top: 5px;
}

.error-message {
  padding: 10px;
  text-align: center;
  background-color: #fef8f8;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
  color: #dc3545;
  margin: 5px 0;
  font-size: 14px;
}

.option-selector select:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

/* 추가 CSS 스타일 */
.option-selector .loading-dates,
.option-selector .error-message {
  width: 100%;
  box-sizing: border-box;
}

.option-selector {
  margin-bottom: 15px;
}

.option-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
}

.option-icon {
  margin-right: 8px;
  color: #495057;
}

.option-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  color: #495057;
}

.option-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* 강습권만 구매 옵션 스타일 */
.voucher-only-option {
  background: #eff6ff;
  border: 1px solid #dbeafe;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 20px;
  position: relative;
}

.voucher-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: #2563eb;
}

.voucher-checkbox {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  accent-color: #4f46e5;
  cursor: pointer;
}

.voucher-label-text {
  margin-right: 8px;
}

.voucher-tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.voucher-tooltip-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: #2563eb;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  cursor: help;
  vertical-align: middle;
}

.voucher-tooltip-text {
  visibility: hidden;
  width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  font-weight: normal;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.voucher-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.voucher-tooltip:hover .voucher-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.voucher-info {
  background-color: #f0f9ff;
  border-radius: 4px;
  padding: 8px;
  margin-top: 5px;
}