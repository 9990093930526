/* ─────────────────────────────────────────────────────────
   LocationPage.css
   ───────────────────────────────────────────────────────── */

/* 페이지 레이아웃 */
.location-page {
  padding: 12px;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 60px;
}

/* 페이지 타이틀 */
.page-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 50px 0 16px;
  text-align: center;
  color: #333;
}

/* 뒤로가기 버튼 (모바일 전용) */
.back-button {
  all: unset;
  cursor: pointer;
  position: absolute;
  top: 70px;
  left: 20px;
  font-size: 1.5rem;
  color: #000;
  z-index: 9999;
}

.back-button.mobile::before {
  content: '←';
}

@media (min-width: 769px) {
  .back-button.mobile {
    display: none;
  }
}

/* 카테고리 버튼 영역 */
.category-buttons {
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-bottom: 12px;
  overflow-x: auto;
  padding: 6px 10px;
  white-space: nowrap;
  scrollbar-width: none; 
}
.category-buttons::-webkit-scrollbar {
  display: none;
}

/* 카테고리 버튼 */
.category-button {
  background: #f4f4f4;
  border: 1px solid #ddd;
  padding: 8px 14px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  min-width: 75px;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
.category-button.active {
  background: #1a73e8;
  color: #fff;
  border-color: #1a73e8;
  font-weight: 600;
  box-shadow: 0 3px 8px rgba(26,115,232,0.3);
}
.category-button:hover {
  background: #e8f0fe;
  color: #1a73e8;
}

/* 장소 리스트 (기본: column) */
.locations-grid {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* 장소 카드 (모바일 기준) */
.location-card {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: row;
  height: 80px;
}
.location-card:hover {
  transform: translateY(-2px);
}

/* 전체 전용 카드 */
.whole-location-card {
  justify-content: center;
  align-items: center;
}

/* 이미지 영역 */
.location-image {
  position: relative;
  width: 80px;
  min-width: 80px;
  height: 80px;
}
.location-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 즐겨찾기 */
.favorite-badge {
  position: absolute;
  top: 4px;
  right: 4px;
  color: gold;
  font-size: 1rem;
}

/* 텍스트 영역 */
.location-info {
  padding: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* 장소명 */
.location-info h3 {
  margin: 0 0 4px 0;
  font-size: 0.95rem;
  font-weight: bold;
  white-space: normal;
  word-break: break-word;
}

.address-container {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 2px;
}
.address {
  font-size: 0.7rem;
  color: #666;
  white-space: normal;
  word-break: break-word;
  max-width: 200px;
}

.copy-button {
  background: none;
  border: none;
  color: #1a73e8;
  padding: 2px 6px;
  font-size: 0.75rem;
  cursor: pointer;
  border-radius: 4px;
}
.copy-button:hover {
  background: #e8f0fe;
}

.operating-hours {
  margin: 2px 0 0 0;
  font-size: 0.8rem;
  color: #666;
  white-space: normal;
  word-break: break-word;
  line-height: 1.3;
}

/* "자세히 보기" 버튼 */
.detail-button {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.5rem;
  margin-top: 0px;
  cursor: pointer;
  align-self: flex-start;
}
.detail-button:hover {
  background: #005fcc;
}

/* 데스크톱 (768px~) → 그리드 2열 */
@media (min-width: 768px) {
  .locations-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .location-card {
    flex-direction: column;
    height: auto;
    align-items: center;
    text-align: center;
    min-height: 250px;
  }
  .location-image {
    width: 100%;
    height: 200px;
  }
  .location-image img {
    border-radius: 8px;
  }
  .location-info {
    padding: 12px;
    justify-content: flex-start;
  }
  .location-info h3 {
    font-size: 1rem;
  }
  .address {
    font-size: 0.9rem;
    max-width: 100%;
  }
  .operating-hours {
    font-size: 0.9rem;
  }
}

/* 데스크톱 (1024px~) → 그리드 4열 */
@media (min-width: 1024px) {
  .locations-grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .location-image {
    height: 250px;
  }
}

/* ─────────────────────────────────────────────────────────
   모달(Modal) 스타일
   ───────────────────────────────────────────────────────── */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 16px;
}

.modal-content {
  background: white;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 20px;
}

.modal-close {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 4px;
  line-height: 1;
}

.modal-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0 0 16px;
  padding-right: 24px;
}

.modal-map {
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.modal-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detail-item h3 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 4px;
  color: #333;
}

.detail-item p {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
  line-height: 1.5;
}

.facilities-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
}

.facility-tag {
  background: #f5f5f5;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 0.8rem;
  color: #333;
}

.modal-action-button {
  width: 100%;
  padding: 12px;
  background: #1a73e8;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 24px;
}
.modal-action-button:hover {
  background: #1557b0;
}

@media (max-width: 480px) {
  .modal-content {
    padding: 16px;
  }
  
  .modal-title {
    font-size: 1.2rem;
  }
  
  .detail-item h3 {
    font-size: 0.95rem;
  }
  
  .detail-item p {
    font-size: 0.85rem;
  }
}
