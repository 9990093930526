.course-card {
  display: flex;
  flex-direction: column;
  /* 기존 스타일 */
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 auto;
  background-color: #f9f9f9;
  font-size: 12px;
  max-width: 85%;
  cursor: pointer;
}

/* 상단: 3개의 영역으로 균등(or 비율) 분할 */
.course-card-top {
  display: flex;
  align-items: center;
  /* 공간이 부족해도 세 영역이 고정 혹은 일정 비율로 나누도록 */
  /* 방법 1) flex-basis 비율로 분할 */
  /* flex: 1 1 auto 등 다양한 방법 시도 가능 */
  margin-bottom: 6px;
}

.course {
  /* 왼쪽 영역: 강습명(예: "체험(Day1)") */
  flex: 1 1 40%;        /* 전체 중 40% 비율 할당 */
  font-size: 12px;
  font-weight: bold;
  color: #333;
  white-space: nowrap;  
  overflow: hidden;     
  text-overflow: ellipsis;
}

.course-time {
  /* 가운데 영역: 시간 ("13:00 - 15:00") */
  flex: 0 0 30%;        /* 전체 중 30% 폭 할당 (고정) */
  text-align: center;
  font-size: 12px;
  color: #555;
  margin-right: 8px; /* 여유 간격 */
}

.course-capacity {
  /* 오른쪽 영역: 정원/대기 표시 ("현재 3 / 정원 10 (대기 2명)") */
  flex: 0 0 30%;
  text-align: right;
  font-size: 12px;
  color: #555;
}

.course-card-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  margin-top: 4px;
}

/* 강사명/종목 정보 */
.course-instructor, .course-sport {
  font-size: 10px;
  color: #333;
  margin-right: 4px;
}

.course-sport {
  color: #777;
  margin-right: 0;
}

.divider {
  margin: 0 5px;
}
