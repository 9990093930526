.travel-cards-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .travel-card {
    width: 100%;
    margin: 0 1rem;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .travel-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .travel-card-image {
    width: 100%;
    height: auto;
  }
  
  .travel-card-content {
    padding: 0.3rem;
    margin-left:3rem;
  }
  
  .travel-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.2rem 0;
    color: #2c3e50;
  }
  
  .travel-card-date, .travel-card-guides {
    color: #555;
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }
  