/* 공통 컨테이너 스타일 */
.Bottom-scroll-events-container {
    position: relative;
    width: 90vw;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 775px;
}

.Bottom-scroll-event-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 10px; /* ✅ 상하좌우 모두 둥글게 */
    background-color: #fff;
    overflow: hidden; /* ✅ 내부 컨텐츠가 border-radius에 맞도록 */
    margin: 0 auto; /* 중앙 정렬 */
    box-shadow: none; /* ✅ 하단 그림자 제거 */
}

/* 공통 이미지 스타일 */
.Bottom-scroll-event-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px; /* ✅ 이미지 자체도 둥글게 */
}


@media (max-width: 1024px) {
    .Bottom-scroll-events-container {
        top: 5px; /* 모바일에서는 기본 위치 유지 */
        width: 90vw; /* 모바일에서는 전체 너비 유지 */
    }

    .Bottom-scroll-event-item {
        width: 350px;
        height: 85px;
        border-radius: 10px; /* ✅ 모바일에서도 동일한 border-radius */
        box-shadow: none; /* ✅ 모바일에서도 그림자 제거 */
    }

    .Bottom-scroll-event-image {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 10px; /* ✅ 이미지도 둥근 모서리 유지 */
    }
}

