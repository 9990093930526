.apply-history {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f4f4f9;
    color: #2c3e50;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
}

.apply-history h2 {
    text-align: center;
}

.apply-history h3 {
    margin-top:3rem;
}


.timeline {
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.timeline-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    transition: transform 0.2s ease-in-out;
    gap: 5px; /* 아이콘과 텍스트 사이의 간격을 명확하게 지정 */
}

.timeline-item:hover {
    transform: translateY(-3px);
}


.timeline-icon {
    width: 60px;
    height: 60px; /* 정사각형을 보장하기 위해 높이도 명시 */
    background-color: #d4ebf2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2c3e50;
    font-weight: bold;
    font-size: 1.2rem;
    flex-shrink: 0; /* 아이콘이 축소되지 않도록 설정 */
}

/* 모바일 화면에서의 정사각형 유지 */
@media (max-width: 448px) {
    .timeline-icon {
        width: 50px;
        height: 50px; /* 너비와 동일한 크기로 설정 */
        flex-shrink: 0; /* 아이콘이 축소되지 않도록 설정 */
    }
}



.icon-placeholder {
    text-align: center;
}

.timeline-content {
    flex-grow: 1;
    margin-left: 15px;
}

.timeline-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #7f8c8d;
}

.timeline-content h3 {
    margin: 5px 0;
    font-size: 1.2rem;
    color: #2c3e50;
}

.timeline-content p {
    margin: 2px 0;
    color: #34495e;
    font-size: 1rem;
}

.timeline-action {
    color: #2980b9;
    display: flex;
    align-items: center;
    justify-content: center;
}
