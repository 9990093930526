.shopping-page {
    min-height: 100vh;
    background-color: #f8f9fa;
    padding-bottom: 60px;
    position: relative;
  }
  
  .filter-section {
    padding: 16px;
    background: white;
    position: relative;
  }
  
  .total-count {
    font-size: 14px;
    color: #666;
    margin-bottom: 12px;
  }
  
  .filter-buttons {
    display: flex;
    gap: 8px;
    padding: 8px 0;
    position: relative;
    z-index: 100;
  }
  
  .filter-button-group {
    position: relative;
  }
  
  .filter-button {
    height: 32px;
    padding: 0 12px;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    background: white;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    cursor: pointer;
  }
  
  .filter-button.active {
    border-color: #5F3DC4;
    color: #5F3DC4;
    background: white;
  }
  
  .filter-button .icon {
    font-size: 12px;
    margin-left: 2px;
    transition: transform 0.2s ease;
  }
  
  .filter-button.active .icon {
    transform: rotate(180deg);
  }
  
  .section-title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 16px;
  }
  
  /* 그리드 레이아웃으로 카드 배치 */
  .product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  
  /* 개별 카드 스타일 */
  .product-card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  /* 이미지 영역을 정사각형으로 만들기 */
  .product-image {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 정사각형 비율 유지 */
  }
  
  .product-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* 좋아요 버튼 */
  .favorite-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
  }
  
  .favorite-button .material-icons {
    font-size: 20px;
    color: #999;
  }
  
  .favorite-button.active .material-icons {
    color: #ff4757;
  }
  
  /* 카드 내부 텍스트 */
  .product-info {
    padding: 12px;
  }
  
  .product-brand {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 4px;
  }
  
  .product-title {
    font-size: 0.95rem;
    font-weight: 600;
    margin: 0 0 8px 0;
    line-height: 1.3;
  }
  
  .product-price {
    margin-bottom: 8px;
  }
  
  .discount-label {
    font-size: 0.8rem;
    color: #ff4757;
    margin-bottom: 2px;
  }
  
  .original-price {
    font-size: 0.8rem;
    color: #999;
    text-decoration: line-through;
    margin-bottom: 2px;
  }
  
  .current-price {
    font-size: 1.1rem;
    font-weight: 700;
  }
  
  .product-likes {
    font-size: 0.8rem;
    color: #666;
  }
  
  /* 하단 고정 네비게이션 */
  .bottom-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #eee;
    z-index: 100;
  }
  
  .nav-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    border: none;
    background: none;
    color: #999;
    font-size: 0.7rem;
    cursor: pointer;
  }
  
  .nav-button.active {
    color: #333;
  }
  
  .nav-button .material-icons {
    font-size: 1.3rem;
    margin-bottom: 2px;
  }

  .sort-options {
    margin-top: 10px;
  }
  
  .sort-option {
    border: none;
    background-color: #f5f5f5;
    padding: 8px 12px;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .sort-option.active {
    background-color: #333;
    color: #fff;
  }
  
  .discount-rate {
    color: #ff5858;
    font-size: 1em;
    font-weight: bold;
  }
  
  .current-price {
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    min-width: 120px;
    z-index: 1000;
    padding: 6px 0;
  }
  
  .dropdown button {
    width: 100%;
    padding: 8px 12px;
    border: none;
    background: none;
    text-align: left;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown button:hover {
    background-color: #f8f9fa;
  }
  
  .dropdown button.selected {
    color: #5F3DC4;
    font-weight: 500;
  }
  
  .dropdown button .check-icon {
    color: #5F3DC4;
    font-size: 12px;
    margin-left: 4px;
  }
  
  /* 반응형: 화면 크기에 따라 카드 컬럼 수 증가 */
  @media (min-width: 768px) {
    .product-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .product-grid {
      grid-template-columns: repeat(4, 1fr);
    }
    
    .filter-section {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
  