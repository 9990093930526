.travelproduct-input-container {
    max-width: 800px;
    margin: 1rem auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.travelproduct-section-title {
    text-align: center;
    margin: 6rem 0 2rem 0;
    font-size: 2rem;
    color: #333;
}

.travelproduct-form-group {
    margin-bottom: 15px;
}

.travelproduct-input-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 두 개의 동일한 열 */
    gap: 4rem;
    margin: 0 2rem 1rem 2rem;
}

.travelproduct-input-row-schedule {
    display: grid; /* flex에서 grid로 변경 */
    grid-template-columns: 45% 10% 45%; /* 비율 설정 */
    gap: 2rem;
    margin: 0 2rem 1rem 2rem;
}

.travelproduct-input-item-schedule1 {
    display: flex;
    align-items: center;
    width: 100%; /* 그리드 셀 전체 너비 차지 */
}

.travelproduct-input-item-schedule2 {
    display: flex;
    align-items: center;
    width: 80%; /* 그리드 셀 전체 너비 차지 */
}

.travelproduct-input-row-schedule span {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 800px) {
    .travelproduct-input-row {
        grid-template-columns: 1fr; /* 800px 미만에서는 한 개의 열 */
        gap: 1rem;
    }

    .travelproduct-input-item-schedule {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%; /* 전체 너비 차지 */
    }

    .travelproduct-input-row-schedule-mobile {
        display: flex;
        margin: 0 2rem 1rem 2rem;
    }

    .travelproduct-input-row-schedule-mobile label {
        flex: 0 0 100px; /* 라벨 너비 고정 */
        font-size: 1.2rem;
        margin-right: 10px;
    }

    .travelproduct-form-control-mobile {
        width: 100%;
        padding: 10px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
}

.travelproduct-input-item {
    display: flex;
    align-items: center;
    width: 100%; /* 그리드 셀 전체 너비 차지 */
}

.travelproduct-input-item label {
    flex: 0 0 100px; /* 라벨 너비 고정 */
    font-size: 1.2rem;
    margin-right: 10px;
}

.travelproduct-input-item:last-child {
    margin-right: 0;
}

.travelproduct-form-control {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.travelproduct-form-control-end-date {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.travelproduct-date-picker {
    cursor: pointer;
}

.travelproduct-custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.div-input-save {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.travelproduct-div-input-save {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.travelproduct-save-button {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.travelproduct-save-button:hover {
    background-color: #0056b3;
}

.travelproduct-form-group-detailed {
    height: 46rem;
}

.travelproduct-form-group-detailed label {
    margin-left: 2rem;
    font-size: 1.2rem;
}

.ql-container {
    padding-bottom: 4rem; /* 에디터 하단에 추가적인 패딩 */
    height: 40rem;
    margin: 0 2rem;
}

.ql-editor {
    height: 40rem;
}

.ql-toolbar.ql-snow {
    margin: 0 2rem;
}