 
 @media (min-width: 1024px) {
  .main-container {
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
  }
 }
 @media (max-width: 1024px) {
 .main-container {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    align-items: center;
    margin: 0 auto;
    padding: 0;
  }
}

.title-container {
  display: flex;
  margin-top: 4rem;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem 0;
  color: #2c3e50;
  text-align: center;
}

.icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 2rem 1rem 0;
}

.title-icon {
  font-size: 2rem;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}

.title-icon:hover {
  color: #000;
}

.filters {
  margin-bottom: 20px;
}

.filter-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.filter-item {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.custom-select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
}

.custom-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
}

.calendar-container {
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
}

.custom-calendar {
  width: 98% !important;
  margin: 0 auto !important;
  border: none !important;
  font-family: 'Roboto', sans-serif !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
  background-color: #f8f9fa !important;
}

.react-calendar__navigation {
  background-color: #34495e !important;
  color: white !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  border-bottom: 1px solid #ecf0f1 !important;
}

.react-calendar__navigation button {
  color: white !important;
  font-size: 1rem !important;
  margin: 0 5px !important;
}

.react-calendar__navigation button:disabled {
  color: #95a5a6 !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #2c3e50 !important;
  border-radius: 50% !important;
}

.react-calendar__tile {
  max-width: 100% !important;
  padding: 15px 0 !important;
  background-color: #fff !important; /* 모든 날짜의 배경색을 흰색으로 통일 */
  color: #2c3e50 !important; /* 모든 날짜의 글자 색상을 동일하게 통일 */
  border-radius: 12px !important;
  border: 1px solid #ecf0f1 !important;
  transition: background-color 0.3s, color 0.3s, transform 0.2s !important;
  text-align: center !important;
}

.react-calendar__tile--hover {
  background-color: #e8f6ff !important; /* 호버 시 부드러운 파란색으로 변경 */
  color: #2980b9 !important;
  border-color: #2980b9 !important;
  transform: translateY(-3px) !important;
}

.react-calendar__tile--now {
  background-color: #fffbcc !important; /* 오늘 날짜 배경색 */
  color: #2c3e50 !important;
  font-weight: bold !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #e74c3c !important; /* 주말 날짜 색상 (빨간색) */
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #95a5a6 !important; /* 다른 월의 날짜 색상 (회색) */
}

.react-calendar__month-view__days__day {
  color: #2c3e50 !important; /* 기본적으로 모든 날짜를 평일 색상으로 설정 */
}

.sunday {
  color: #e74c3c !important;  /* Red color for Sundays */
}

.saturday {
  color: #2c3e50 !important;  /* Black color for Saturdays (or same as weekday color) */
}

/* 선택된 특정 날짜의 스타일 */
.custom-selected-date {
  padding: 2px;
  background-color: #8e44ad !important;  /* !important로 우선순위 높임 */
  color: white !important;
  font-weight: bold !important;
  border-radius: 4% !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) !important;
  transition: transform 0.2s ease, background-color 0.2s ease !important;
}

.clicked-date {
  padding: 2px;
  background-color: #e74c3c !important;  /* 클릭된 날짜의 배경색 */
  color: white !important;
  font-weight: bold !important;
  border-radius: 4% !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) !important;
  transition: transform 0.2s ease, background-color 0.2s ease !important;
}


.custom-selected-date:hover,
.clicked-date:hover {
  transform: scale(1.1);  /* 마우스를 올리면 살짝 확대 */
}

.form-group-detailed {
  margin-top: 2rem;
  height: 46rem;  /* ql-editor와 동일한 높이로 설정 */
}

.ql-container {
  padding-bottom: 4rem; /* 에디터 하단에 추가적인 패딩 */
  height : 40rem;
}

.ql-editor {
  height : 40rem;
}

.div-input-save {
  margin-top : 2rem;
  margin-bottom : 2rem;
  text-align: center;
}
.save-button {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.input-container {
  max-width: 800px;
  margin: 1rem auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-section-title {
  text-align: center;
  margin: 6rem 0 2rem 0;
  font-size: 2rem;
  color: #333;
}

.input-form-group {
  margin-bottom: 15px;
}

.input-row {
  display: grid;
  grid-template-columns: 1fr 1fr; /* 두 개의 동일한 열 */
  gap: 4rem;
  margin: 0 2rem 1rem 2rem;
}

.input-item {
  display: flex;
  align-items: center;
  width: 100%; /* 그리드 셀 전체 너비 차지 */
}

.input-item label {
  flex: 0 0 100px; /* 라벨 너비 고정 */
  font-size: 1.2rem;
  margin-right: 10px;
}

.input-item:last-child {
  margin-right: 0;
}

.react-datepicker__time-container {
  width: 100% !important; /* 시간 선택 컨테이너의 너비를 100%로 설정 */
}

.react-datepicker__time {
  width: 100% !important; /* 시간 선택 목록의 너비를 100%로 설정 */
}

.react-datepicker__time-box {
  width: 100% !important; /* 시간 선택 박스의 너비를 100%로 설정 */
}

/* 448px 초과의 화면에서 적용되는 스타일 */
@media (min-width: 800px) {
  .react-calendar {
    font-size: 1.2rem; /* 기본 글자 크기 */
  }

  .react-calendar__navigation button {
    font-size: 1.4rem !important; /* 월과 연도 버튼의 글자 크기 */
  }

  .react-calendar__tile {
    font-size: 1.3rem !important; /* 날짜 타일의 글자 크기 */
    padding: 20px 0 !important; /* 날짜 타일의 패딩을 증가시켜 버튼 크기와 글자 크기를 조화롭게 */
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 1.2rem !important; /* 요일 글자 크기 */
    padding: 10px 0 !important; /* 요일 패딩 */
  }
}

@media (max-width: 800px) {
  .input-row {
      grid-template-columns: 1fr; /* 800px 미만에서는 한 개의 열 */
      gap: 1rem;
  }
}