.shopping-page {
    min-height: 100vh;
    padding-bottom: 80px;
    background: #f8f9fa;
  }
  
  .app-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: white;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .logo {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .login-info {
    cursor: pointer;
    color: #333;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .page-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 8px 0 16px;
    text-align: center;
  }
  
  .search-bar {
    padding: 16px;
    background: white;
    margin-bottom: 12px;
  }
  
  .search-input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #eee;
    border-radius: 8px;
    font-size: 0.9rem;
    outline: none;
  }
  
  .package-grid {
    padding: 0 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  
  /* 태블릿 */
  @media (min-width: 768px) {
    .package-grid {
      padding: 0 16px;
      gap: 16px;
    }
  }
  
  /* 데스크톱 */
  @media (min-width: 1024px) {
    .package-grid {
      grid-template-columns: repeat(4, 1fr);
      max-width: 1200px;
      margin: 0 auto;
    }
  }
  
  .package-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease-in-out;
  }
  
  .package-card:hover {
    transform: translateY(-4px);
  }
  
  .package-image {
    position: relative;
    padding-top: 75%;
  }
  
  .package-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .package-category {
    position: absolute;
    top: 8px;
    left: 8px;
    background: rgba(0, 123, 255, 0.9);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
  }
  
  .package-info {
    padding: 12px;
  }
  
  .package-info h3 {
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.4;
    height: 2.8em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .package-price {
    margin: 8px 0 0;
    font-weight: 600;
    font-size: 1rem;
    color: #333;
  }
  
  /* 모바일에서 텍스트 크기 조정 */
  @media (max-width: 767px) {
    .package-info h3 {
      font-size: 0.8rem;
    }
    
    .package-price {
      font-size: 0.9rem;
    }
    
    .package-category {
      font-size: 0.7rem;
      padding: 3px 6px;
    }
  }