/* .bottom-navigation { 
    width: 100%; 
    height: 55px; 
    background-color: #ffffff; 
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); 
    position: fixed; 
    bottom: 0;  
    left: 0;
    z-index: 1000;
} 

.bottom-nav-container {
    width: 100%;
    max-width: 700px; 
    margin: 0 auto; 
    padding: 0 20px; 
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bottom-nav-link-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #343a40;
}

.bottom-nav-icon {
    width: 24px; 
    height: 24px;
    margin-bottom: 4px; 
} */

.bottom-navigation {
    width: 100%;
    height: 60px; /* 기본 높이 조정 */
    background-color: #ffffff;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.08); /* 더 섬세한 음영 */
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
  
  .bottom-nav-container {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 0 15px;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .bottom-nav-link-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #2D3748; /* 더 진한 색상으로 가독성 향상 */
    padding: 8px; /* 터치 영역 보장 */
  }
  
  .bottom-nav-icon {
    width: 28px; /* 아이콘 크기 확대 */
    height: 28px;
    margin-bottom: 5px; /* 텍스트 간격 증가 */
  }
  
  .bottom-nav-link-item span {
    font-size: 13px;
    font-weight: 600; /* 기본 굵기 조정 */
  }
  
  /* 반응형 조정 */
  @media (max-width: 480px) {
    .bottom-nav-container {
      padding: 0 10px;
    }
    
    .bottom-nav-icon {
      width: 24px;
      height: 24px;
    }
    
    .bottom-nav-link-item span {
      font-size: 12px;
    }
  }
  