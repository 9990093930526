.sportsinput-sports-input-container {
    max-width: 800px;
    margin: 1rem auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.sportsinput-section-title {
    text-align: center;
    margin: 2rem 0;
    font-size: 2rem;
    color: #333;
}

.sportsinput-form-group {
    margin-bottom: 15px;
}

.sportsinput-input-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 두 개의 동일한 열 */
    gap: 4rem;
    margin: 0 2rem 1rem 2rem;
}

@media (max-width: 800px) {
    .sportsinput-input-row {
        grid-template-columns: 1fr; /* 800px 미만에서는 한 개의 열 */
        gap: 1rem;
    }
}

.sportsinput-input-item {
    display: flex;
    align-items: center;
    width: 100%; /* 그리드 셀 전체 너비 차지 */
}

.sportsinput-input-item label {
    flex: 0 0 100px; /* 라벨 너비 고정 */
    margin-right: 10px;
    font-size: 1.2rem;
}

.sportsinput-input-item:last-child {
    margin-right: 0;
}

.sportsinput-form-control {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.sportsinput-date-picker {
    cursor: pointer;
}

.sportsinput-custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.sportsinput-div-input-save {
    margin-top : 2rem;
    margin-bottom : 2rem;
    text-align: center;
}
.sportsinput-save-button {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sportsinput-save-button:hover {
    background-color: #0056b3;
}

.sportsinput-form-group-detailed {
    height : 46rem;
}

.sportsinput-form-group-detailed label {
    margin-left: 2rem;
    font-size: 1.2rem;
}

.ql-container {
    padding-bottom: 4rem; /* 에디터 하단에 추가적인 패딩 */
    height : 40rem;
    margin: 0 2rem;
}

.ql-editor {
    height : 40rem;
}

.ql-toolbar.ql-snow {
    margin: 0 2rem;
}

.message-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    z-index: 1000;
    min-width: 300px;
}

.message-box p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.messagebox-close-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.messagebox-close-button:hover {
    background-color: #0056b3;
}

.sportsinput-toggle-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.sportsinput-toggle-container {
    flex: 1;
}

.sportsinput-toggle-switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 34px;
}

.sportsinput-toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.sportsinput-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.sportsinput-toggle-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.sportsinput-toggle-switch input:checked + .sportsinput-toggle-slider {
    background-color: #2196F3;
}

.sportsinput-toggle-switch input:focus + .sportsinput-toggle-slider {
    box-shadow: 0 0 1px #2196F3;
}

.sportsinput-toggle-switch input:checked + .sportsinput-toggle-slider:before {
    transform: translateX(calc(100% - 30px));
    right: 4px;
    left: auto;
}

.sportsinput-toggle-label {
    flex: 0 0 100px; /* 라벨 너비 고정 */
    margin-right: 10px;
    font-size: 1.2rem;
}
