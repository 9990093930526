.detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.detail-image {
    width: 100%;
    max-width: 800px;
    border-radius: 20px;
    margin-bottom: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.detail-content {
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.detail-content h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 10px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
}

.detail-subcontent {
    font-size: 1.2rem;
    font-weight: bold;
    color: #4a5568;
    margin-bottom: 10px;
}

.additional-info {
    background-color: #e9f5ff;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    overflow-x: hidden;
}

.additional-info p {
    font-size: 1.1rem;
    color: #2c3e50;
    margin: 0;
    line-height: 1.6;
}

.apply-button {
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    width: 52%;
    border: none;
    color: white;
    padding: 6px 10px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
}

.wait-button {
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    width: 100%;
    border: none;
    color: white;
    padding: 6px 10px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
}

.apply-button:hover, .wait-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.apply-button:active, .wait-button:active {
    transform: translateY(1px);
}

.alipay-button {
    width: 44%;
    background-color: #00a1e9;
    color: white;
    border: none;
    padding: 10px 10px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alipay-button:hover {
    background-color: #0077b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 161, 233, 0.3);
}

.alipay-button i {
    margin-right: 0.5rem;
}

.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.mr-2 {
    margin-right: 0.5rem;
}

/* 모달 스타일 */
.modal-content {
    padding: 0 !important;
    border-radius: 20px;
    border: none;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
}

.modal-header {
    border-bottom: none;
    padding: 2rem 2rem 1rem;
}

.modal-schedule-title {
    font-size: 2.0rem !important;
    font-weight: 700;
    color: #333;
    width: 100%;
    text-align: center;
}

.modal-body {
    padding-left: 1rem;
}

.participant-info table {
    width: 100%;
    border-collapse: collapse;
}

.participant-info th, .participant-info td {
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.participant-info th {
    font-weight: 600;
    color: #333;
    width: 30%;
}

.participant-info td {
    color: #555;
}

.modal-footer {
    border-top: none;
    padding: 1rem 2rem 2rem;
    justify-content: center;
}

.btn-outline-secondary {
    color: #fff;
    background-color: #6c757d;
    border: none;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    transition: all 0.3s ease;
}

.btn-outline-secondary:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(108, 117, 125, 0.3);
}
