.login_ReactModal__Content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 390px !important;
  margin: 0 auto !important;
  background: white !important;
  border-radius: 20px !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  outline: none !important;
}

.login-ReactModal__Overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000 !important;
}

.login-modal-content {
  width: 100%;
  max-width: 340px;
  margin: 0 auto;
  padding: 40px 10px;
}

.login-modal-header {
  text-align: left;
  margin-bottom: 40px;
}

.login-modal-header h2 {
  font-size: 32px;
  font-weight: 600;
  color: #1A1A1A;
  margin: 0;
}

.input-group {
  margin-bottom: 32px;
}

.input-group label {
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: #999999;
  margin-bottom: 12px;
}

.modal-input {
  width: 100%;
  height: 56px;
  padding: 0;
  border: none;
  border-bottom: 1.5px solid #E5E5E5;
  font-size: 18px;
  color: #1A1A1A;
  transition: border-color 0.2s;
  background: transparent;
}

.modal-input::placeholder {
  color: #999999;
  font-size: 18px;
}

.modal-input:focus {
  outline: none;
  border-bottom-color: #6C38FF;
}

.login-modal-footer {
  margin-top: 40px;
}

.modal-button {
  width: 100%;
  height: 56px;
  background-color: #6C38FF;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 0 auto;
}

.modal-button:hover {
  background-color: #5829E3;
}

.modal-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 12px;
}

.signup-link, .find-password-link {
  color: #6C38FF;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}

.link-separator {
  color: #E5E5E5;
  font-size: 16px;
}

.signup-link:hover, .find-password-link:hover {
  text-decoration: underline;
}

.close-button {
  display: none;
}