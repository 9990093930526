.payment-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f9fc;
  padding: 1rem;
  padding-top: 10px;
  margin-top: 30px;
}

.result-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  text-align: center;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.payment-header {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.result-title {
  font-size: 1.4rem;
  font-weight: 500;
  color: #333;
  margin: 1rem 0;
}

.result-message-box {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.result-message-box.success {
  background-color: #e8f4fd;
}

.result-message-box.failure {
  background-color: #fee8e7;
}

.result-message-box h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

.result-message-box p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.payment-details {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  text-align: left;
}

.payment-info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.payment-info-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.payment-info-item .label {
  color: #666;
  font-size: 0.95rem;
}

.payment-info-item .value {
  color: #333;
  font-weight: 500;
  font-size: 0.95rem;
  text-align: right;
  max-width: 60%;
  word-break: break-word;
}

.payment-amount {
  margin-top: 1.5rem;
  border-top: 1px solid #ddd;
  padding-top: 1.5rem;
}

.payment-amount .label,
.payment-amount .value {
  font-size: 1.1rem;
  font-weight: 600;
}

.payment-amount .value {
  color: #f15a24;
}

.payment-points, 
.payment-review {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 0.7rem 1rem;
  margin-top: 0.5rem;
}

.payment-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.home-button, 
.details-button {
  flex: 1;
  border: none;
  padding: 1rem 0;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home-button {
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
}

.details-button {
  background-color: #f15a24;
  color: white;
}

.home-button:hover {
  background-color: #f5f5f5;
}

.details-button:hover {
  background-color: #e04b15;
}
