.user-profile {
  margin-top: 4rem;
  max-width: 800px;
  padding-top: 40px;
  background-color: #f8f9fa;
}

.profile-nav {
  background-color: #ffffff;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-nav .nav-item {
  flex: 1;
  text-align: center;
}

.profile-nav .nav-link {
  font-size: 1.2rem;
  padding: 15px 25px;
  color: #495057;
  transition: all 0.3s ease;
}

.profile-nav .nav-link:hover,
.profile-nav .nav-link.active {
  background-color: #007bff;
  color: #ffffff;
}

.profile-nav .nav-link .fa-icon {
  margin-right: 8px;
}

.user-info,
.user-purchases,
.user-history {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #343a40;
}

.user-history {
  margin-top: 20px;
}

.action-icon {
  font-size: 1.2rem;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.action-icon:hover {
  transform: scale(1.2);
}

.edit-icon {
  color: #007bff;
}

.delete-icon {
  color: #dc3545;
}

.member-icon {
  color: #28a745;
}

.user-history table {
  width: 100%;
  border-collapse: collapse;
}

.user-history th,
.user-history td {
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}

.user-history td:nth-child(2) {
  white-space: pre-line;
  line-height: 1.5;
}

.view-icon {
  color: #4a90e2;
  transition: all 0.3s ease;
}

.view-icon:hover {
  color: #2a6fc2;
  transform: scale(1.2);
}

.user-purchases {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.purchases-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.purchases-list {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.purchases-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 15px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.purchases-header span {
  font-size: 1.2rem;
  text-align: center;
}

.purchase-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 15px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s;
  cursor: pointer;
}

.purchase-item:hover {
  background-color: #f1f3f5;
}

.purchase-item span {
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  text-align: center;
}

.status.paid {
  color: rgba(29, 8, 120, 0.848);
}

.status.unpaid {
  background-color: #dc3545;
  color: white;
}

.userprofile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998; /* 메시지 박스보다 낮은 z-index 값 */
}

.userprofile-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.userprofile-modal-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.userprofile-modal-body {
  margin-bottom: 20px;
}

.participant-list-title {
  font-size: 18px;
  color: #555;
  margin-bottom: 15px;
}

.participant-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.participant-item {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.participant-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0,0,0,0.15);
}

.participant-item.expanded {
  background-color: #f8f9fa;
}

.participant-name {
  font-weight: bold;
  color: #333;
}

.participant-notes {
  margin-top: 10px;
}

.participant-notes textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.participant-notes button {
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.participant-notes button:hover {
  background-color: #0056b3;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-button.end-course {
  background-color: #dc3545;
  color: white;
}

.modal-button.end-course:hover {
  background-color: #c82333;
}

.modal-button.close {
  background-color: #6c757d;
  color: white;
}

.modal-button.close:hover {
  background-color: #5a6268;
}

.purchase-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.purchase-info-item {
  display: flex;
  flex-direction: column;
}

.info-label {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 5px;
}

.info-value {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
}

.userprofile-modal-footer {
  margin-top: 20px;
  text-align: center;
}

.userprofile-modal-close {
  width: 100%;
  position: relative;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.userprofile-modal-close:hover {
  background-color: #0056b3;
}

.purchase-details-table {
  width: 100%;
  border-style: none !important;
  border-collapse: collapse;
  margin-top: 20px;
}

.purchase-details-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.info-label {
  font-weight: bold;
  color: #555;
}

.info-value {
  color: #333;
}

.status {
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
}

.status.paid {
  color: rgb(41, 77, 240);
}

.status.unpaid {
  color: rgb(226, 7, 7);
}

.product-type-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.type-button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.type-button.active {
  background-color: #007bff;
  color: white;
}

.type-button:hover {
  background-color: #e0e0e0;
}

.type-button.active:hover {
  background-color: #0056b3;
}

.delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.delete-modal {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.delete-modal-content {
  text-align: center;
  margin: 1rem 0;
}

.delete-modal-content h2 {
  margin-bottom: 15px;
  color: #333;
}

.delete-modal-content p {
  margin-bottom: 20px;
  color: #666;
}

.delete-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.confirm-button, .cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirm-button {
  background-color: #dc3545;
  color: white;
}

.confirm-button:hover {
  background-color: #c82333;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.message-box {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 9999; /* 모달보다 높은 z-index 값 */
  max-width: 80%;
  text-align: center;
}

.message-box p {
  margin-bottom: 10px;
}

.message-box button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.message-box button:hover {
  background-color: #0056b3;
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #c82333;
}

/* 애니메이션 */
@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

@keyframes slideDown {
  from { opacity: 0; transform: translate(-50%, -20px); }
  to { opacity: 1; transform: translate(-50%, 0); }
}

/* 테이블 스타일 업데이트 */
.table {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.table thead th {
  background-color: #007bff;
  color: #ffffff;
  border: none;
}

.table tbody tr:hover {
  background-color: #f1f3f5;
}

.table td, .table th {
  vertical-align: middle;
}

/* 로그아웃 모달 */
.userprofile-modal-content .userprofile-modal-title {
  font-size: 1.8rem;
  color: #12447b;
}

.userprofile-modal-content .userprofile-modal-footer .userprofile-modal-button.close {
  width: 6rem;
  padding: 10px;
  font-size: 1.2rem;
  color: white;
  border-radius: 20px;
  background-color: #12447b;
}

.userprofile-modal-content .userprofile-modal-footer .userprofile-modal-button.close:hover {
  background-color: #14441f;
}

/* 반응형 디자인 */
@media (max-width: 576px) {
  .profile-nav .nav-link {
    padding: 10px 15px;
    font-size: 1rem;
  }

  .logout-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .userprofile-modal-content {
    padding: 15px;
  }

  .modal-title {
    font-size: 1.5rem;
  }

  .purchases-header, .purchase-item {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .purchases-header span:nth-child(4),
  .purchase-item span:nth-child(4) {
    display: none;
  }
}

.modal-content {
  position: relative;
  /* ... 기존 스타일 ... */
}

.modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.modal-close-icon:hover {
  color: #f00;
}
