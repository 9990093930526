.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.modal-content {
  background: white;
  padding: 80px 60px;
  border-radius: 16px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 600px; /* ✅ 데스크톱 기본 크기 */
  min-height: 450px; /* ✅ 최소 높이 */
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* X 닫기 버튼 */
.close-icon {
  position: absolute;
  top: 10px;
  right: -20px; /* ✅ 데스크톱에서는 오른쪽 밖으로 이동 */
  background: none;
  border: none;
  font-size: 30px; /* ✅ 버튼 크기 증가 */
  cursor: pointer;
  color: #666;
}

.close-icon:hover {
  color: #333;
}

/* 기본 로고 크기 (데스크톱) */
.logo {
  width: 300px; /* ✅ 데스크톱에서는 큰 로고 */
  display: block;
  margin: 0 auto 30px;
  margin-bottom: 10px;
}

.close-icon {
  top: 10px;
  right: 10px; /* ✅ 모바일에서는 오른쪽 안쪽에 위치 */
  font-size: 25px; /* ✅ X 버튼 크기 축소 */
}

footer {
  margin-top: 30px;
  font-size: 12px; /* ✅ 기본 폰트 크기 (데스크톱) */
  color: #888;
}
.info {
  margin-top: 15px; /* ✅ 기존보다 조금 더 아래로 내리기 */
  margin-bottom: -20px;
}

.logo-deeps {
  transition: transform 0.3s ease;
  cursor: pointer;
}

.logo-deeps:hover {
  transform: scale(1.08); /* 마우스를 올리면 살짝 확대 */
}

/* 클릭 안내 문구 스타일 */
.click-hint {
  font-size: 12px;
  color: #555;
  margin-top: 5px;
  animation: bounce 1.5s infinite;
}

/* 문구에 약한 애니메이션 추가 (선택적) */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

.deeps-link-container.small {
  background-color: #f0f9ff;
  padding: 10px 15px;
  border-radius: 8px;
  margin: 25px auto 0;
  width: 90%;
  max-width: 350px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  font-size: 13px;
  text-align: center;
}

.deeps-link-container.small .logo-deeps {
  width: 100px; /* 로고 더 작게 조정 */
  margin-bottom: 5px;
}

.deeps-title-small {
  font-size: 14px;
  color: #0077b6;
  margin: 5px 0 0;
}

.modal-content h2 {
  margin-top: -5px;
  margin-bottom: 5px; 
}

.modal-content p {
  margin-bottom: 12px;
  line-height: 1.4;
}

.deeps-link-container.small {
  margin-top: 10px; 
}


/* ✅ 모바일 화면 (작은 화면) */
@media screen and (max-width: 768px) {
  .modal-content {
    padding: 50px 30px; /* ✅ 패딩 줄이기 */
    max-width: 90%; /* ✅ 가로 크기 90% */
    min-height: 450px; /* ✅ 최소 높이 350px */
  }

  .close-icon {
    top: 10px;
    right: -15px; /* ✅ 모바일에서는 오른쪽 안쪽에 위치 */
    font-size: 25px; /* ✅ X 버튼 크기 축소 */
  }

  .info {
    margin-top: 15px;
    margin-bottom: -20px;
  }

  .logo {
    width: 250px; /* ✅ 모바일에서 로고 크기 축소 */
  }

  footer {
    font-size: 10px; /* ✅ 모바일에서는 더 작은 폰트 크기 */
  }
  
}
